<template>
  <section>
    <!--地图-->
    <div id="allmap"></div>
  </section>
</template>
<script>
import NProgress from "nprogress";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      temperature: "-",
      humidity: "-",
      HistoryPtArray: [],
      nowPoint: null
    };
  },
  computed: {
    ...mapGetters(["customerid"])
  },
  methods: {
    destroySocket: function() {
      this.socket.close();
    }
  },
  destroyed() {
    let that = this;
    setTimeout(function() {
      that.destroySocket();
    }, 5000);
    // 五秒后断开websocket
  },
  mounted() {
    // 百度地图API功能
    var map = new BMap.Map("allmap"); // 创建Map实例
    const onsoftPoint = new BMap.Point(119.978934, 31.835868);
    map.centerAndZoom(onsoftPoint, 15); // 初始化地图,设置中心点坐标和地图级别
    map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    // 添加带有定位的导航控件
    let navigationControl = new BMap.NavigationControl({
      // 靠左上角位置
      anchor: BMAP_ANCHOR_TOP_LEFT,
      // LARGE类型
      type: BMAP_NAVIGATION_CONTROL_LARGE,
      // 启用显示定位
      enableGeolocation: true
    });
    map.addControl(navigationControl);
    // 添加定位控件
    var geolocationControl = new BMap.GeolocationControl();
    geolocationControl.addEventListener("locationSuccess", function(e) {
      // 定位成功事件
      let address = "";
      address += e.addressComponent.province;
      address += e.addressComponent.city;
      address += e.addressComponent.district;
      address += e.addressComponent.street;
      address += e.addressComponent.streetNumber;
      console.log("当前定位地址为：" + address);
    });
    geolocationControl.addEventListener("locationError", function(e) {
      // 定位失败事件
      console.log(e.message);
    });
    map.addControl(geolocationControl);
    let sContent = `<div><h4 style='margin:0 0 5px 0;padding:0.2em 0'>常州至上软件有限公司</h4>
            <p style='margin:0;line-height:1.5;font-size:12px;text-indent:1em;color:gray;'>服务电话:0519-85068639-8007</p>
            <p style='margin:0;line-height:1.5;font-size:12px;text-indent:1em;color:gray;'>技术支持:0519-85068639-8003</p>
            <p style='margin:0;line-height:1.5;font-size:12px;text-indent:1em;color:gray;'>传真号码:0519-85177829-8002</p>
            </div>`;
    let infoWindow = new BMap.InfoWindow(sContent);
    map.openInfoWindow(infoWindow, onsoftPoint);
    map.panTo(onsoftPoint);
    let that = this;

    that.socket = new WebSocket(`wss://${window.location.hostname.replace(".cn","")}:30000`);
    that.socket.onopen = event => {
      // 通知wsServer当前浏览器的客户ID
      that.socket.send(that.customerid);

      that.socket.onmessage = msg => {
        console.log(msg.data)
        // 只处理实时数据，过滤实时GPS数据
        if (msg.data.indexOf("|") > 0) {
          let { 0: customerid, 1: devicecode, 2: lng, 3: lat } = msg.data.split(
            "|"
          ); // msg.data： 11|kf0003|113.75|23.03
          // 过滤非本设备的GPS数据
          if (devicecode != that.$router.currentRoute.params.devicecode) {
            return;
          }
          var devicetype=that.$router.currentRoute.params.devicetype;
          map.clearOverlays();
          var carIcon = new BMap.Icon("/pushpin.png", new BMap.Size(25, 25), {
            imageOffset: new BMap.Size(0, 0)
          });
          that.nowPoint = new BMap.Point(lng, lat);
          let carMk = new BMap.Marker(that.nowPoint, {
            icon: carIcon
          });
          let sContent = `<div><h4 style='margin:0 0 5px 0;padding:0.2em 0'>${devicecode}</h4>
            <p style='margin:0;line-height:1.5;font-size:13px;text-indent:2em'>${that.temperature} ℃</p>
            ${devicetype!=6?'<p style=\'margin:0;line-height:1.5;font-size:13px;text-indent:2em\'>'+that.humidity+' %</p>':''}
            <p style='margin:0;line-height:1.5;font-size:10px;text-indent:2em;color:gray;'>最后更新:${new Date().format(
              "yyyy-mm-dd HH:MM:ss"
            )}</p>
            </div>`;
          let infoWindow = new BMap.InfoWindow(sContent);
          map.openInfoWindow(infoWindow, that.nowPoint);
          map.removeOverlay(that.preMarker);
          map.addOverlay(carMk);
          map.panTo(that.nowPoint);
          that.HistoryPtArray.push(that.nowPoint);
          if (that.HistoryPtArray.length > 0) {
            let polyline = new BMap.Polyline(that.HistoryPtArray);
            map.addOverlay(polyline);
          }
        }
        if (msg.data.indexOf(",") > 0) {
          let {
            0: customerid,
            1: devicecode,
            2: sensorcode,
            3: temperature,
            4: humidity,
            5: flag
          } = msg.data.split(",");
          if (devicecode == that.$router.currentRoute.params.devicecode)
            that.temperature = temperature;
          that.humidity = humidity;
          that.sensorcode = sensorcode;
        }
      };
    };
    that.socket.onclose = closeEvent => {
      // that.$message({
      // 	message: 'websocket断开',
      // 	type: 'warning'
      // });
    };
    that.socket.onerror = errorEvent => {
      // that.$message({
      // 	message: 'websocket出错',
      // 	type: 'warning'
      // });
    };
  }
};
</script>
<style scope>
/* The container of BaiduMap must be set width & height. */

#allmap {
  width: 98%;
  margin: 0 auto;
  height: 600px;
  border: 1px #ccc solid;
}
</style>
